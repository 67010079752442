export const environment = {
  production: true,
  apiUrl: "https://loyalty.planet.vg",
  stsServer: "https://auth.planet.vg/realms/planetvg",
  stripe_apiKey: "pk_live_51IYd8AK3BjU4t8elehWzboXSBrWJKEPbQTKgufU0cyAIYjQubhsBdboidYmgo6f65Uf81Sv2sbaBE609CFTlpH2300EfxjyQes",
  accountPortalUrl: "https://account.planet.vg",
  // Crude Feature Flag
  enabledCheckout : true,
  storefrontUrl : "https://www.planet.vg",
};
